<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>ออกผลรางวัล</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />
      <div class="card-body">

        <div v-if="!isAvalibleStatus" class="alert alert-warning mb-0">
          <i class="fas fa-exclamation-triangle"></i> ไม่สามารถออกผลได้ ({{roundStatus}})
        </div>
        <div v-else-if="!isRoundClosed" class="alert alert-warning mb-0">
          <i class="fas fa-exclamation-triangle mr-2"></i>
          <RoundStatus v-if="round" :data="round" @cssStyle="setStatusStyle" class="badge badge-status font-weight-normal" :class="[cssStatusClass.bg, cssStatusClass.text]" style="font-size: 100%;" />
          ยังไม่ถึงเวลาออกผล
        </div>

        <div v-else>
          <div v-if="isPaid" class="alert alert-success mb-4">
            <i class="far fa-check-circle"></i> จ่ายเงินแล้ว
          </div>
          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">3 ตัวบน</label>
            </div>
            <div class="col-sm-9 form-inline">
              <span v-if="isPaid" class="badge badge-success mr-1">{{award.three}}</span>
              <b-form-input
                v-else
                placeholder="3 ตัวบน"
                v-model="award.three"
                v-on:keydown="keypress"
                maxlength="3">
              </b-form-input>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">3 ตัวโต๊ด</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in threeNumberTode" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">2 ตัวบน</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in twoNumberTop" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-success font-weight-bold mb-0">วิ่งบน</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in runTop" class="badge badge-success mr-1">{{number}}</span>
            </div>
          </div>

          <hr>
          <div class="row mb-2">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-danger font-weight-bold mb-0">2 ตัวล่าง</label>
            </div>
            <div class="col-sm-9 form-inline">
              <span v-if="isPaid" class="badge badge-danger mr-1">{{award.two}}</span>
              <b-form-input
                v-else
                placeholder="2 ตัวล่าง"
                v-model="award.two"
                v-on:keydown="keypress"
                maxlength="2"
              ></b-form-input>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-3 d-flex align-items-center">
              <label class="text-danger font-weight-bold mb-0">วิ่งล่าง</label>
            </div>
            <div class="col-sm-9 text-primary">
              <span v-for="number in runBottom" class="badge badge-danger mr-1">{{number}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAvalibleStatus && isRoundClosed && !isPaid" class="card-footer text-right">
        <b-button variant="primary" @click="saveResults"><i class="far fa-save"></i> บันทึกผล</b-button>
      </div>
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import RoundCardHeader from './components/RoundCardHeader'
import { marketOpenBets } from "@/configs/market.config"
import moment from '@/helpers/moment'
import RoundStatus from './components/RoundStatus'
import _ from 'lodash'

export default {
  name: 'PayHalfNumbers',
  components: {
    RoundCardHeader,
    RoundStatus
  },
  data() {
    return {
      round: null,
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      },
      award: {
        three: '',
        two: ''
      }
    }
  },
  computed: {
    marketOpenBets() {
      return marketOpenBets
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    roundStatus() {
      return this.round?.status
    },
    isRoundClosed() {
      if(!this.round)
        return false

      if(moment().isBefore(this.round.roundDate.close))
        return false

      return true
    },
    isAvalibleStatus() {
      if(!this.round)
        return false

      return ['Open', 'Close', 'Complete', 'Paid'].includes(this.round.status)
    },
    isPaid() {
      return this.round?.status === 'Paid'
    },
    awardThree() {
      if(!this.award.three)
        return null

      const awards = this.award.three.slice('')
      if(awards.length !== 3)
        return null

      return awards
    },
    awardTwo() {
      if(!this.award.two)
        return null

      const awards = this.award.two.slice('')
      if(awards.length !== 2)
        return null

      return awards
    },
    threeNumberTop() {
      if(!this.awardThree)
        return []

      return [this.awardThree]
    },
    threeNumberTode() {
      if(!this.awardThree)
        return []

      const awards = [
        `${this.awardThree[0]}${this.awardThree[1]}${this.awardThree[2]}`,
        `${this.awardThree[0]}${this.awardThree[2]}${this.awardThree[1]}`,
        `${this.awardThree[1]}${this.awardThree[0]}${this.awardThree[2]}`,
        `${this.awardThree[1]}${this.awardThree[2]}${this.awardThree[0]}`,
        `${this.awardThree[2]}${this.awardThree[1]}${this.awardThree[0]}`,
        `${this.awardThree[2]}${this.awardThree[0]}${this.awardThree[1]}`,
      ]
      return _.uniqBy(awards)
    },
    twoNumberTop() {
      if(!this.awardThree)
        return []

      return [`${this.awardThree[1]}${this.awardThree[2]}`]
    },
    runTop() {
      if(!this.awardThree)
        return []

      const awards = [`${this.awardThree[0]}`, `${this.awardThree[1]}`, `${this.awardThree[2]}`]
      return _.uniqBy(awards)
    },
    twoNumberBottom() {
      if(!this.awardTwo)
        return []

      return [this.awardTwo]
    },
    runBottom() {
      if(!this.awardTwo)
        return []

      const awards = [`${this.awardTwo[0]}`, `${this.awardTwo[1]}`]
      return _.uniqBy(awards)
    },
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.getRound()
      }
    }
  },
  methods: {
    getRound() {
      LottoService.getRound(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.round = response.data
          this.award = {
            three: this.round.results.threeNumberTop.join(''),
            two: this.round.results.twoNumberBottom.join('')
          }
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    },
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    },
    keypress(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    },
    saveResults() {

      if(!this.awardTwo || !this.awardThree)
        return Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'กรุณากรอกผล'
        })

      const results = {
        threeNumberTop: this.threeNumberTop,
        threeNumberTode: this.threeNumberTode,
        twoNumberTop: this.twoNumberTop,
        twoNumberBottom: this.twoNumberBottom,
        runTop: this.runTop,
        runBottom: this.runBottom
      }

      Swal.fire({
        title: 'ยืนยันบันทึกผล?',
        text: "กรุณาตรวจสอบผลให้ถูกต้องก่อนกดยืนยัน",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return LottoService.saveResults(this.round._id, results).then((response)=>{
            if(response.success) {
              return response.data
            }else{
              throw new Error(response?.message || JSON.stringify(response?.data))
            }
          })
          .catch((error)=>{
            Swal.fire(
              'ผิดพลาด!',
              (error.message||'บันทึกผลหวยไม่สำเร็จ'),
              'error'
            )
          })
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'สำเร็จ!',
            'บันทึกผลหวยสำเร็จ',
            'success'
          )
          .then(()=>{
            // this.getRound()
            this.$router.push({name: 'PayWinTickets'})
          })
        }
      })
    }
  },
  mounted() {
    this.getRound()
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 100%;
  font-weight: normal;
  letter-spacing: 1px;
}
</style>
